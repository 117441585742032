import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading, Text } from 'theme-ui'
import { FaRegHandshake } from 'react-icons/fa'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    size: `full`,
    p: 4,
  },
  heading: {
    color: `omegaDark`,
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3,
    },
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`,
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  button: {
    display: `block`,
    mt: `auto`,
  },
}

const BannerVerticalGS = () => (
  // <Flex sx={styles.wrapper}>
  //   <Heading variant='h1' sx={styles.heading}>
  //     {/* <FaGifts /> */}
  //     <Button
  //     variant='primary'
  //     as={Link}
  //     to='https://baba.ge/products'
  //     sx={styles.button}
  //     aria-label='Learn More'
  //   >
  //     Baba.ge
  //   </Button>
  //   </Heading>
  //   <Heading variant='h3' sx={styles.subheading}>
  //    შეიძინე ონლაინ
  //   </Heading>
  //   <Text as='ul' variant='small' sx={styles.list}>
  //     <li>სწრაფი მიწოდება</li>
  //     <li>საიმედო მომსახურება</li>
  //     <li>მაღალი ხარისხი</li>
  //   </Text>

  // </Flex>

  <Flex sx={styles.wrapper}>
<Heading variant='h1' sx={styles.heading}>
  {/* <FaGifts /> */}
  <Button
  variant='primary'
  as={Link}
  to='https://www.dabudu.com/'
  sx={styles.button}
  aria-label='Learn More'
>
  Dabudu.com
</Button>
</Heading>
<Heading variant='h3' sx={styles.subheading}>
 ისწავლე და იმუშავე ონლაინ
</Heading>
<Text as='ul' variant='small' sx={styles.list}>
  <li>ინგლისური ენის შესწავლა</li>
  <li>დასაქმება დისტანციურად</li>
</Text>

</Flex>

)

export default BannerVerticalGS
